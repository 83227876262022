.App {
  background-color: black;
  color: white;
  font-weight: bolder;
  text-align: left;
  /* padding-left: 30px; */
  padding-top: 20px;


  /* border-style: dotted; */
}

.website_background {
  min-height: 100vh;
  background: rgb(146,113,246);
  background: linear-gradient(0deg, rgba(146,113,246,1) 0%, rgba(71,74,182,1) 35%);
}

.logo_name{
  text-align: left;
  font-size: 30px;
  padding-left: 1%;

}

.About{
height: 800px;
}

.ImageStyle{
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: auto;
  margin-bottom: auto; */
}

.slide-right {
  width: 100%;
  overflow: hidden;
}

.slide-right h2 {
  animation: 2s slide-right;
  animation-delay: 0s;
}

.slide-right p {
  animation: 2s slide-right;
  animation-delay: 0s;
}

@keyframes slide-right {
  from {
    margin-left: -500px;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slide-right {
  from {
    margin-left: -500px;
  }

  to {
    margin-left: 0%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.root-class {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* align-items: center;
  justify-content: center; */
}

.component-class {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.component-Contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}



.wrapper {
  /* transform: translate(-50%, -50%);
  position: absolute */
}

img {
  display: block;
  width: 80%;
  width: auto;
}

.content {
  position: absolute;
  top: 45%;
  left:50%;
  transform: translate(-50%,-50%);
  color: '#fff';
  text-transform: uppercase;
  font-size: 28px;
}

.overlay {
  position: absolute;
  top: 10px;
  left: 5px;
  right:5px;
  bottom:5px;
  height:342px;
  width: 339px;
  opacity: 0;
  transition: .5s ease;
  background: #343a40;
  padding-top:20px;
  border-radius: .25rem;
}

.wrapper:hover .overlay {
  opacity: 0.8;
}

.learnMore{
  transition: .5s ease;
  borderColor:'#343a40';
  background: #343a40;
  color: white
}

.learnMore:hover {
  background-color: white;
  borderColor:'#343a40';
  color: #343a40;
}

.grow{
  transition: all .2s ease-in-out;
}

.grow:hover{
  transform: scale(1.2);
}